
.systemBtn {
  --button-fade: var(--transition-fade);
  --button-radius: calc(var(--grid-unit) * 4);

  position: relative;
  display: inline-block;
  padding: var(--grid-unit) calc(var(--grid-unit) * 2);
  font-variant-numeric: lining-nums;
  color: var(--button-text-color);
  text-align: center;
  cursor: pointer;
  background-color: var(--button-bg-color);
  border: 0 solid transparent;
  border-radius: var(--button-radius);
  outline: none;
  transition:
    color var(--button-fade),
    background-color var(--button-fade);

  &__text {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: none;
    white-space: nowrap;

    * > span {
      display: inline-block;
    }
  }

  &:hover {
    text-decoration: none;
  }

  &:focus {
    box-shadow: 0 0 0 3px var(--input-focus-color);
  }

  &:disabled,
  &[disabled] {
    color: var(--button-text-color-disabled);
    cursor: no-drop;
    background-color: var(--button-bg-color-disabled);
  }

  &:not([disabled]),
  &:not(:disabled) {
    &:hover,
    &:focus {
      color: var(--button-text-color-hover);
      background-color: var(--button-bg-color-hover);
    }

    &:active {
      color: var(--button-text-color-active);
      background-color: var(--button-bg-color-active);
    }
  }

  &__icon {
    margin-right: var(--grid-unit);
    font-size: var(--font-icon-medium);
    color: inherit;
    transition: color var(--button-fade);
  }

  &--icon-only {
    .systemBtn__icon {
      margin-right: 0;
    }
  }

  &.fullWidth {
    width: 100%;
  }

  &.small {
    padding: calc(var(--grid-unit) / 4) var(--grid-unit);

    .systemBtn__icon {
      margin-right: calc(var(--grid-unit) / 2);
      font-size: var(--font-icon-small);
    }
  }

  &.primary {
    --button-text-color-active: var(--button-text-color);
    --button-text-color-hover: var(--button-text-color);

    &--viridian {
      --button-text-color: var(--gray-0);
      --button-text-color-disabled: var(--viridian-0);
      --button-bg-color: var(--viridian-50);
      --button-bg-color-disabled: #99CCC2;
      --button-bg-color-hover: var(--viridian-60);
      --button-bg-color-active: var(--viridian-70);
    }

    &--periwinkle {
      --button-text-color: var(--periwinkle-80);
      --button-text-color-disabled: #ACADBB;
      --button-bg-color: var(--periwinkle-20);
      --button-bg-color-disabled: #DCDFFF;
      --button-bg-color-hover: var(--periwinkle-30);
      --button-bg-color-active: var(--periwinkle-40);
    }

    &--light-periwinkle {
      --button-text-color: var(--periwinkle-20);
      --button-text-color-disabled: var(--periwinkle-5);
      --button-bg-color: var(--periwinkle-0);
      --button-bg-color-disabled: var(--periwinkle-10);
      --button-bg-color-hover: var(--periwinkle-10);
      --button-bg-color-active: var(--periwinkle-30);
    }

    &--coral {
      --button-text-color: var(--coral-80);
      --button-text-color-disabled: #C7A6A1;
      --button-bg-color: var(--coral-20);
      --button-bg-color-disabled: #FDD1D0;
      --button-bg-color-hover: var(--coral-30);
      --button-bg-color-active: var(--coral-40);
    }

    &--gray {
      --button-text-color: var(--gray-0);
      --button-text-color-disabled: var(--gray-40);
      --button-bg-color: var(--gray-50);
      --button-bg-color-disabled: var(--gray-15);
      --button-bg-color-hover: var(--gray-60);
      --button-bg-color-active: var(--gray-70);
    }

    &--gold {
      --button-text-color: var(--gold-80);
      --button-text-color-disabled: #C6B39D;
      --button-bg-color: var(--gold-20);
      --button-bg-color-disabled: #FFEFC1;
      --button-bg-color-hover: var(--gold-30);
      --button-bg-color-active: var(--gold-40);
    }

    &--rust {
      --button-text-color: var(--gray-0);
      --button-text-color-disabled: var(--rust-0);
      --button-bg-color: var(--rust-50);
      --button-bg-color-disabled: #E8B6A1;
      --button-bg-color-hover: var(--rust-60);
      --button-bg-color-active: var(--rust-50);
    }

    &--fuchsia {
      --button-text-color: var(--fuchsia-80);
      --button-text-color-disabled: var(--fuchsia-0);
      --button-bg-color: var(--fuchsia-20);
      --button-bg-color-disabled: #D3B6D6;
      --button-bg-color-hover: var(--fuchsia-30);
      --button-bg-color-active: var(--fuchsia-40);
    }
  }

  &.secondary {
    &--viridian {
      --button-text-color: var(--viridian-80);
      --button-text-color-disabled: #99B2AD;
      --button-bg-color: var(--viridian-10);
      --button-bg-color-disabled: #E1F1E8;
      --button-bg-color-hover: var(--viridian-15);
      --button-bg-color-active: var(--viridian-20);
    }

    &--periwinkle {
      --button-text-color: var(--periwinkle-80);
      --button-text-color-disabled: #ACADBB;
      --button-bg-color: var(--periwinkle-5);
      --button-bg-color-disabled: #F3F4FF;
      --button-bg-color-hover: var(--periwinkle-10);
      --button-bg-color-active: var(--periwinkle-15);
    }

    &--coral {
      --button-text-color: var(--coral-80);
      --button-text-color-disabled: #C7A6A1;
      --button-bg-color: var(--coral-5);
      --button-bg-color-disabled: #FDEFEF;
      --button-bg-color-hover: var(--coral-10);
      --button-bg-color-active: var(--coral-15);
    }

    &--gray {
      --button-text-color: var(--gray-80);
      --button-text-color-disabled: var(--gray-20);
      --button-bg-color: var(--gray-5);
      --button-bg-color-disabled: var(--gray-5);
      --button-bg-color-hover: var(--gray-10);
      --button-bg-color-active: var(--gray-15);
    }

    &--gold {
      --button-text-color: var(--gold-80);
      --button-text-color-disabled: #C6B39D;
      --button-bg-color: var(--gold-5);
      --button-bg-color-disabled: #FFF9E9;
      --button-bg-color-hover: var(--gold-10);
      --button-bg-color-active: var(--gold-15);
    }

    &--rust {
      --button-text-color: var(--rust-80);
      --button-text-color-disabled: #C7A799;
      --button-bg-color: var(--rust-5);
      --button-bg-color-disabled: #FFF2ED;
      --button-bg-color-hover: var(--rust-10);
      --button-bg-color-active: var(--rust-15);
    }

    &--fuchsia {
      --button-text-color: var(--fuchsia-80);
      --button-text-color-disabled: #BCA6BE;
      --button-bg-color: var(--fuchsia-5);
      --button-bg-color-disabled: #FAF1FB;
      --button-bg-color-hover: var(--fuchsia-10);
      --button-bg-color-active: var(--fuchsia-15);
    }
  }

  &.clear {
    --button-bg-color: transparent;
    --button-bg-color-disabled: transparent;
    --button-bg-color-hovered: transparent;
    --button-bg-color-active: transparent;

    &--viridian {
      --button-text-color: var(--viridian-50);
      --button-text-color-disabled: #99CCC2;
      --button-text-hovered: var(--viridian-60);
      --button-text-active: var(--viridian-70);
    }

    &--periwinkle {
      --button-text-color: var(--periwinkle-50);
      --button-text-color-disabled: #BFC2DD;
      --button-text-hovered: var(--periwinkle-60);
      --button-text-active: var(--periwinkle-70);
    }

    &--coral {
      --button-text-color: var(--coral-50);
      --button-text-color-disabled: #E8B6A1;
      --button-text-hovered: var(--coral-60);
      --button-text-active: var(--coral-70);
    }

    &--gray {
      --button-text-color: var(--gray-50);
      --button-text-color-disabled: var(--gray-15);
      --button-text-hovered: var(--gray-60);
      --button-text-active: var(--gray-70);
    }

    &--gold {
      --button-text-color: var(--gold-50);
      --button-text-color-disabled: #E5CEAB;
      --button-text-hovered: var(--gold-60);
      --button-text-active: var(--gold-70);
    }

    &--rust {
      --button-text-color: var(--rust-50);
      --button-text-color-disabled: var(--rust-15);
      --button-text-hovered: var(--rust-60);
      --button-text-active: var(--rust-70);
    }

    &--fuchsia {
      --button-text-color: var(--fuchsia-50);
      --button-text-color-disabled: #D3B6D6;
      --button-text-hovered: var(--fuchsia-60);
      --button-text-active: var(--fuchsia-70);
    }
  }

  &.inline {
    display: inline-block;
    text-align: inherit;
    letter-spacing: inherit;

    &.clear {
      padding: 0;
      border: none;

      & .systemBtn__text {
        font: inherit;
        letter-spacing: inherit;
        white-space: inherit;
      }

      & .systemBtn__text > span:last-child {
        text-decoration: underline;
      }
    }
  }
}
