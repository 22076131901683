
.box {
  --light-bg-color: var(--periwinkle-0);
  --bg-color: var(--periwinkle-5);
  --icon-bg-color: var(--periwinkle-20);
  --text-color: var(--periwinkle-80);

  padding: calc(var(--grid-unit) * 2);
  color: var(--text-color);
  background-color: var(--bg-color);
  border: 1px solid transparent;
  border-radius: var(--radius);

  &--light-bg {
    background-color: var(--light-bg-color);
  }

  &--centered {
    text-align: center;
  }

  .fal {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: calc(var(--grid-unit) * 3);
    height: calc(var(--grid-unit) * 3);
    margin-right: calc(var(--grid-unit) * 2);
    font-size: var(--font-icon-large);
    background-color: var(--icon-bg-color);
    border-radius: 100%;

    &.no-bg {
      background-color: transparent;
    }
  }

  .fas {
    margin-right: var(--grid-unit);

    &.no-bg {
      background-color: transparent;
    }
  }

  &--with-icon {
    display: flex;
    align-items: center;
  }

  &--big-radius {
    border-radius: var(--radius-big);
  }

  &--white {
    --bg-color: var(--gray-0);
    --icon-bg-color: var(--gray-80);
    --text-color: var(--gray-80);

    border: 1px solid var(--gray-20);
  }

  &--periwinkle-light {
    --bg-color: var(--periwinkle-0);
    --icon-bg-color: var(--periwinkle-5);
    --text-color: var(--periwinkle-60);
  }

  &--periwinkle-dark {
    --bg-color: var(--periwinkle-15);
    --icon-bg-color: var(--periwinkle-50);
  }

  &--viridian {
    --bg-color: var(--viridian-5);
    --icon-bg-color: var(--viridian-20);
    --text-color: var(--viridian-80);
  }

  &--viridian-light {
    --bg-color: var(--viridian-0);
    --icon-bg-color: var(--viridian-5);
    --text-color: var(--viridian-60);
  }

  &--gold {
    --bg-color: var(--gold-0);
    --icon-bg-color: var(--gold-20);
    --text-color: var(--gold-80);
  }

  &--gold-dark {
    --bg-color: var(--gold-20);
    --icon-bg-color: var(--gold-30);
    --text-color: var(--gold-80);
  }

  &--rust {
    --bg-color: var(--rust-5);
    --icon-bg-color: var(--rust-20);
    --text-color: var(--rust-80);
  }

  &--rust-dark {
    --bg-color: var(--rust-20);
    --icon-bg-color: var(--rust-30);
    --text-color: var(--rust-80);
  }
}
