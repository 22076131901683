
.kFormField {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__label {
    position: relative;
    width: 100%;
    font-weight: 500;
    color: var(--gray-80);

    &--required::after {
      margin-left: var(--grid-unit);
      color: var(--coral-20);
      content: 'Required';
    }
  }

  &__description {
    margin-bottom: var(--grid-unit);
    color: var(--gray-60);
  }

  &__inputWrapper {
    align-self: stretch;
  }

  &__errors {
    color: var(--rust-50);
  }

  .optional {
    color: #616161;
    font-size: calc(var(--grid-unit) * 2);
    font-weight: 400;
  }
}

.kFormField + .kFormField {
  margin-top: calc(var(--grid-unit) * 2);
}

