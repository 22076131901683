
@keyframes loadstar-pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.loadstar {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--z-loader);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  transform: translateY(0);
  animation: loadstar-pulse 1s ease-in-out infinite;

  & > * {
    width: 128px;
    max-width: none;
  }
}

.loading-enter-active,
.loading-leave-active {
  transition: opacity 500ms;
}

.loading-enter-to,
.loading-leave {
  opacity: 1;
}

.loading-enter,
.loading-leave-to {
  opacity: 0;
}
